import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? {display: 'none'} : {}}>
    <div className="logo">
      <span className="icon icon-g5c">
      </span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>g5C - Entwicklung Neu Gedacht</h1>
        <p>
          Wir entwickeln modernste digitale Lösungen für Ihren Erfolg.<br/>
          Dabei verstehen wir uns nicht als reine Entwickler,
          sondern als Digitaler Incubator für Ihr Business.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Apps
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            Über Uns
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Kontakt
          </button>
        </li>
      </ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
};

export default Header
