import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
  <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
    <p className="copyright">&copy; 2010 - {new Date().getFullYear()} g5c.de - All rights reserved |&nbsp;
      <a onClick={() => {
        props.onOpenArticle('contact')
      }}>
        Impressum
      </a>
      <br/>Kein Tracking &amp; Keine Cookies</p>
  </footer>
);

Footer.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
};

export default Footer
